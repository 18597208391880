import React from 'react'
import styled from 'styled-components'

import back from '../images/back.png'
import AniLink from 'gatsby-plugin-transition-link/AniLink'

const Btn = styled(AniLink)`
  display: inline-block;
  width: 280px;
  height: 70px;
  position: absolute;
  bottom: 30px;
  left: 40px;
`

const BackButton = ({ to }) => (
  <Btn cover bg={'#e20074'} duration={1} to={to}>
    <img src={back} />
  </Btn>
)
export default BackButton
