import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

const MenuWrapper = styled.div`
  display: flex;
  margin-top: -30px;
`

const Title = styled.h3`
  font-size: 25px;
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #e20074;
  margin: 0;
  line-height: 1.2;
  text-align: center;
  margin-bottom: -5px;
`

const IconWrapper = styled.div`
  position: relative;
  max-width: 194px;
  margin-left: 52px;
  img {
    width: 100%;
  }
`
const SubMenu = styled.div`
  flex: 1;
  text-align: center;
  line-height: 1;
  text-transform: uppercase;
  color: #383838;
  font-size: 19px;
  h4 {
    margin: 0;
    font-weight: 900;
    height: 2.31em;
    vertical-align: top;
  }
  ul,
  li {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  li {
    height: 2.31em;
    vertical-align: top;
  }
  a {
    color: #383838;
    text-decoration: none;
  }
`

export const MenuLevel1 = ({ data }) => {
  console.log('data menu', data)
  if (!data) return null
  return (
    <MenuWrapper>
      <IconWrapper>
        <Title dangerouslySetInnerHTML={{ __html: data.displayTitle }} />
        <img src={data.menuIcon.url} />
      </IconWrapper>
      {data.treeChildren.map(sub1 => (
        <SubMenu>
          <h4>
            <Link to={`/${sub1.slug}`}>{sub1.displayTitle}</Link>
          </h4>
          <ul>
            {sub1.treeChildren.map(link => (
              <li>
                <Link to={`/${link.slug}`}>{link.displayTitle}</Link>
              </li>
            ))}
          </ul>
        </SubMenu>
      ))}
    </MenuWrapper>
  )
}
