import React, { useState } from 'react'
import styled from 'styled-components'

import { MenuLevel1 } from './MenuLevel1'

const ContentWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  width: 100%;
  transform: translateX(-50%);
  max-width: 1200px;
  //max-width: 1440px;
  margin: auto;
`
const Wrapper = styled.div`
  background: white;
  position: absolute;
  top: 48px;
  transition: height 0.3s ease-out;
  height: ${({ open, lvl }) => (open ? (lvl === 0 ? 360 : 402) : 48)}px;
  width: 100%;
  border-bottom: 1px solid #e6e6e6;
  z-index: 20;
  font-family: 'telegrotesk';
  overflow: hidden;
`
const Backdrop = styled.div`
  background: white;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 19;
  opacity: ${({ open }) => (open ? 0.4 : 0)};
  transition: opacity 0.3s ease-out;
  pointer-events: ${({ open }) => (open ? 'auto' : 'none')};
`

const Toggle = styled.a`
  color: white;
  display: block;
  position: absolute;
  top: 20px;
  left: 96px;
  z-index: 3000;
  cursor: pointer;
  pointer-events: auto;
  color: #000;
  font-family: telegrotesk;
  font-weight: 300;
  font-size: 14px;
  padding-left: 32px;
  span {
    position: absolute;
    background: #000;
    height: 2px;
    width: 26px;
    left: 0px;
    top: 50%;
    margin-top: -2px;
    opacity: ${props => (props.open ? 0 : 1)};
    transition: 0.5s;
    &:first-child {
      top: ${props => (props.open ? '50%' : 0)};
      opacity: 1;
      transform: rotate(${props => (props.open ? '405deg' : 0)});
    }
    &:last-child {
      top: ${props => (props.open ? '50%' : '100%')};
      opacity: 1;
      transform: rotate(${props => (props.open ? '-405deg' : 0)});
    }
  }
`
const Content = styled.div`
  margin-top: 48px;
`

const MenuTitle = styled.h3`
  text-transform: uppercase;
  color: #383838;
  font-size: 29px;
  font-weight: 400;
  border-bottom: 1px solid #383838;
  display: block;
  width: 205px;
  padding: 0 32px;
  padding-bottom: 10px;
  margin: 0 auto;
  margin-bottom: 34px;
  text-align: center;
`

const Level0 = styled.div`
  display: flex;
`

const Level0Item = styled.a`
  text-align: center;
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 178px;
  justify-content: space-between;
  text-decoration: none;
  align-items: center;
  cursor: pointer;
  p {
    color: #e20073;
    font-size: 25px;
    font-weight: 900;
    text-transform: uppercase;
    margin-top: 0;
    margin-bottom: -8px;
  }
  img {
    width: 194px;
    margin: 0 auto;
    margin-bottom: 0;
    position: absolute;
    bottom: -20px;
  }
`

const menuDataBySlug = (data, slug) =>
  data ? data.filter(d => d.slug === slug)[0] : []

export default ({ data, lvl, currentSlug }) => {
  const [open, setOpen] = useState(false)
  const toggleMenu = () => setOpen(!open)
  const currentMenu = menuDataBySlug(data.treeChildren, currentSlug)
  //console.log('top menu', data, currentSlug, currentMenu)
  return (
    <>
      <Wrapper open={open} lvl={lvl}>
        <ContentWrapper>
          <Toggle open={open} onClick={toggleMenu}>
            <span />
            <span />
            <span />
            {open ? 'schliessen' : 'Navigation'}
          </Toggle>
          <Content>
            <MenuTitle>Szenarien</MenuTitle>
            {data.sub && currentSlug ? (
              <MenuLevel1 data={currentMenu} />
            ) : (
              <Level0>
                {data.treeChildren &&
                  data.treeChildren.map(m => (
                    <Level0Item key={`menu0_${m.slug}`} href={`/${m.slug}`}>
                      <p dangerouslySetInnerHTML={{ __html: m.displayTitle }} />
                      <img src={m.menuIcon.url} />
                    </Level0Item>
                  ))}
              </Level0>
            )}
          </Content>
        </ContentWrapper>
      </Wrapper>

      <Backdrop open={open} onClick={toggleMenu} />
    </>
  )
}
