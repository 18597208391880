import React from 'react'
import styled from 'styled-components'
import Link from 'gatsby-link'

import PlayButton from './../images/play-button-gray.png'

const H = styled.h3`
  text-align: right;
  font-size: 25px;
  letter-spacing: 1px;
  margin-bottom: 0.2em;
`
//const Title = styled.h4`
//  font-size: 25px;
//  margin-top: 0;
//  margin-bottom: 1.3em;
//  letter-spacing: 0.8px;
//`
const Subtitle = styled.h5`
  font-size: 19px;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 4px;
  letter-spacing: 0.8px;
`
const Wrapper = styled.div`
  display: flex;
  text-align: right;
`
const CategoryCol = styled.div`
  flex: 1;
  width: 250px;
  margin-left: 20px;
  &:first-child {
    margin-left: 0;
  }
`
const SubCategoryWrapper = styled.div`
  margin-bottom: 18px;
  flex: 1;
`

const ImgWrap = styled.div`
  height: 200px;
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  img {
    margin-top: auto;
    max-width: 200px;
    max-height: 100%;
  }
`
const Ul = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`
const Li = styled.li`
  font-size: 19px;
  line-height: 1.3em;
  a {
    color: #fff;
    text-decoration: none;
  }
`
const Content = styled.div`
  width: 480px;
`
const H4 = styled.h4`
  text-align: right;
  font-size: 29px;
  font-weight: 900;
  border-bottom: 1px solid white;
`

const Attachment = styled.a`
  display: flex;
  position: absolute;
  flex-direction: column;
  text-align: center;
  color: white;
  font-family: telegrotesk;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
  margin-top: -40px;
  left: -250px;
  cursor: pointer;
  z-index: 100;

  height: 160px;

  .icon {
    max-height: 50px;
    position: absolute;
    bottom: 75px;
    right: 3px;
    margin-left: -20px;
  }

  .play-button {
    max-height: 60px;
    position: absolute;
    top: 15px;
    left: 50%;
    margin-left: -30px;
  }

  span {
    position: absolute;
    bottom: 17px;
    width: 100%;
    text-align: center;
    display: block;
    font-size: 17px;
    height: 40px;
  }
`

//const Category = ({ d }) => (
//  <CategoryCol>
//    {d.treeChildren.map(group => <SubCategory d={group} />)}
//  </CategoryCol>
//)

const SubCategory = ({ d }) => (
  <SubCategoryWrapper>
    <Subtitle>{d.displayTitle}</Subtitle>
    <Ul>
      {d.treeChildren.map(link => (
        <Li>
          <Link to={`/${link.slug}`}>{link.displayTitle}</Link>
        </Li>
      ))}
    </Ul>
  </SubCategoryWrapper>
)

const VideoStarter = ({ data, onClick }) => (
  <Attachment onClick={() => onClick(data.videoUrl)}>
    <img alt=" " width={160} height={90} src={data.thumbnail.url} />
    <img alt=" " className="play-button" src={PlayButton} />
    <span>{data.title}</span>
  </Attachment>
)

class MenuContentCategory extends React.Component {
  render() {
    const { data, openVideo } = this.props
    return (
      <div>
        <ImgWrap>
          <img alt=" " src={data.menuIcon ? data.menuIcon.url : ''} />
        </ImgWrap>
        <H>{data.displayTitle}</H>
        <Content>
          <H4>Produkte & L&ouml;sungen</H4>
          {data.attachments && data.attachments.length > 0 ? (
            <VideoStarter onClick={openVideo} data={data.attachments[0]} />
          ) : null}
          <Wrapper>
            {data.treeChildren.map(group => (
              <CategoryCol>
                <SubCategory d={group} />
              </CategoryCol>
            ))}
          </Wrapper>
        </Content>
      </div>
    )
  }
}

export default MenuContentCategory
