import React from 'react'
import styled from 'styled-components'

import MenuContentRoot from './MenuContentRoot'
import MenuContentCategory from './MenuContentCategory'

const OuterWrapper = styled.div`
  position: absolute;
  z-index: 99;
  width: ${props => props.w}px;
  height: ${props => props.h}px;
  min-width: 1024px;
  min-height: 768px;
  top: 0;
  left: 0;
  overflow: hidden;
  pointer-events: ${props => (props.open ? 'auto' : 'none')};
`
const Toggle = styled.a`
  color: white;
  display: block;
  position: absolute;
  top: 20px;
  right: 50px;
  z-index: 3000;
  cursor: pointer;
  pointer-events: auto;
  color: ${props => (props.open ? 'white' : props.color)};
  font-family: telegrotesk;
  font-weight: 900;
  text-transform: uppercase;
  padding-right: 48px;
  span {
    position: absolute;
    background: ${props => (props.open ? 'white' : props.color)};
    height: 4px;
    width: 29px;
    right: 0px;
    top: 50%;
    margin-top: -2px;
    opacity: ${props => (props.open ? 0 : 1)};
    transition: 0.5s;
    &:first-child {
      top: ${props => (props.open ? '50%' : 0)};
      opacity: 1;
      transform: rotate(${props => (props.open ? '405deg' : 0)});
    }
    &:last-child {
      top: ${props => (props.open ? '50%' : '100%')};
      opacity: 1;
      transform: rotate(${props => (props.open ? '-405deg' : 0)});
    }
  }
`
const BackDrop = styled.div`
  width: 100%;
  height: 100%;
  background: white;
  opacity: ${props => (props.open ? 0.7 : 0)};
  z-index: 1;
  transition: all 0.4s ease 0s;
  pointer-events: ${props => (props.open ? 'auto' : 'none')};
`

const MenuBackground = styled.div`
  position: absolute;
  background: #e20074;
  z-index: 2;

  height: 150%;
  width: 150%;
  top: ${props => (props.open ? 30 : 150)}%;
  left: -15%;
  z-index: 2;

  transition: all 0.4s ease 0s;
  transform: rotate(-30deg);
`

const ContentWrapper = styled.div`
  position: absolute;
  color: white;
  font-family: telegrotesk;
  text-transform: uppercase;
  right: 100px;
  top: 200px;
  z-index: 5;
`

class Menu extends React.Component {
  state = {
    baseImgWidth: 2560,
    baseImgHeight: 1536,
    open: false,
  }

  toggleMenu = e => {
    e.preventDefault()
    this.setState({ open: !this.state.open })
  }

  render() {
    const {
      //windowWidth,
      //windowHeight,
      data,
      lvl,
      color,
      openVideo,
    } = this.props
    const { baseImgWidth, baseImgHeight, open } = this.state
    const windowWidth = 1366
    const windowHeight = 1024
    const windowRatio = windowWidth / windowHeight
    const imageRatio = baseImgWidth / baseImgHeight
    const wrapperWidth =
      imageRatio > windowRatio ? windowWidth : windowHeight * imageRatio
    const wrapperHeight =
      imageRatio > windowRatio ? windowWidth / imageRatio : windowHeight

    return (
      <OuterWrapper
        className="menu"
        w={wrapperWidth}
        h={wrapperHeight}
        open={this.state.open}
      >
        <Toggle
          open={open}
          onClick={this.toggleMenu}
          color={color || '#4d4d4d'}
        >
          {open ? 'Schliessen' : 'Navigation'}
          <span />
          <span />
          <span />
        </Toggle>

        <ContentWrapper>
          {lvl === 0 && open && <MenuContentRoot data={data} />}
          {lvl === 1 && open && (
            <MenuContentCategory data={data} openVideo={openVideo} />
          )}
        </ContentWrapper>

        <MenuBackground open={open} />
        <BackDrop open={open} onClick={this.toggleMenu} />
      </OuterWrapper>
    )
  }
}

export default Menu
