import React from 'react'
import styled from 'styled-components'
import Link from 'gatsby-link'

import HotSpots from './HotSpots'
import Header from './Header'
import Menu from './Menu'
import TopMenu from './TopMenu'
import VideoPlayer from './VideoPlayer'

import PDFIcon from './../images/icon_PDF.png'
import PlayButton from './../images/play-button-gray.png'

const Wrapper = styled.div`
  position: absolute;
  background: rgba(255, 255, 255, ${({ shown }) => (shown ? 0.5 : 0)});
  width: 100%;
  height: 100%;
  z-index: 18;
  top: 0;
  left: 0;
  transition: all 0.45s linear;
  pointer-events: ${({ shown }) => (shown ? 'auto' : 'none')};
`

const Background = styled.div`
  position: absolute;
  background: #4d4d4d;
  opacity: 0.95;

  height: 150%;
  width: 150%;
  top: ${({ shown }) => (shown ? 42 : 100)}%;
  left: ${({ shown }) => (shown ? -15 : 100)}%;
  z-index: 2;

  transition: all 0.45s linear 0s;
  transform: rotate(-30deg);
  pointer-events: none;
`

const Content = styled.div`
  opacity: ${({ shown }) => (shown ? 1 : 0)};
  color: white;
  position: absolute;
  bottom: 0;
  //right: 100px;
  right: 50px;
  height: 63%;
  text-align: left;
  z-index: 3;
  font-family: telegrotesk;
  transition: all 0.25s linear;
`
const Title = styled.h2`
  font-size: 28px;
  text-transform: uppercase;
  max-width: 540px;
`
const Body = styled.div`
  font-size: 20px;
  letter-spacing: 0.6px;
  max-width: 540px;
  margin-bottom: 40px;
  line-height: 1.2;
  ul {
    li {
      display: inline-block;
    }
  }
`
const BackButton = styled.button`
  opacity: ${({ shown }) => (shown ? 1 : 0)};
  width: 145px;
  height: 45px;
  background: transparent url(${props => props.icon || ''}) no-repeat top left;
  background-size: contain;
  padding-left: 55px;
  color: white;
  font-family: telegrotesk;
  text-transform: uppercase;
  font-weight: 700;
  position: absolute;
  top: 21%;
  right: 22%;
  z-index: 5;
  line-height: 45px;
  text-decoration: none;
  border: none;
  cursor: pointer;
  transition: all 0.25s ease-out;
`

const LinksWrapper = styled.div`
  display: flex;
  position: absolute;
  right: 0;
  padding-top: 10px;
  //bottom: 70px;
  bottom: 30px;
`
const Attachment = styled.a`
  display: flex;
  position: relative;
  flex-direction: column;
  text-align: center;
  color: white;
  font-weight: 400;
  text-transform: uppercase;
  text-decoration: none;
  margin-left: 30px;
  margin-right: 0px;
  height: ${props => (props.showIcon ? 210 : 200)}px;
  cursor: pointer;
  width: 220px;

  > div {
    height: 50px;
    img {
      position: absolute;
      left: 0;
    }
  }

  .icon {
    max-height: 50px;
    position: absolute;
    bottom: 75px;
    right: 3px;
    margin-left: -20px;
  }

  .play-button {
    max-height: 60px;
    position: absolute;
    top: ${props => (props.showIcon ? 65 : 15)}px;
    left: 50%;
    margin-left: -30px;
  }

  span {
    position: absolute;
    bottom: 17px;
    width: 100%;
    text-align: left;
    display: block;
    font-size: 14px;
    height: 40px;
  }
`

const ExternalLink = ({ data, showIcon }) => (
  <Attachment href={data.url} target="_blank">
    <img alt=" " width={data.thumbnail.width / 2} src={data.thumbnail.url} />
    <span>{data.title}</span>
  </Attachment>
)

const PDF = ({ data, showIcon }) => (
  <Attachment
    href={data.filename || data.file.url}
    showIcon={showIcon}
    target="_blank"
  >
    {showIcon ? (
      <div>
        {showIcon && data.icon ? (
          <img alt=" " height={40} src={data.icon.url} />
        ) : null}
      </div>
    ) : null}
    <img alt=" " width={220} height={124} src={data.thumbnail.url} />
    {/*<img alt=" " className="icon" src={PDFIcon} />*/}
    <span>{data.title}</span>
  </Attachment>
)

const VideoStarter = ({ data, onClick, showIcon }) => (
  <Attachment onClick={() => onClick(data.videoUrl)} showIcon={showIcon}>
    {showIcon ? (
      <div>
        {showIcon && data.icon ? (
          <img alt=" " height={40} src={data.icon.url} />
        ) : null}
      </div>
    ) : null}
    <img alt=" " width={160} height={90} src={data.thumbnail.url} />
    <img alt=" " className="play-button" src={PlayButton} />
    <span>{data.title}</span>
  </Attachment>
)

const Attachments = ({ onStartVideo, links, icons }) => (
  <LinksWrapper>
    {links.map(l => {
      switch (l.__typename) {
        case 'DatoCmsPdf':
          return <PDF data={l} showIcon={icons} />
        case 'DatoCmsLink':
          return <ExternalLink data={l} showIcon={icons} />
        case 'DatoCmsVideo':
          return (
            <VideoStarter onClick={onStartVideo} data={l} showIcon={icons} />
          )
        default:
          return <ExternalLink data={l} />
      }
    })}
  </LinksWrapper>
)

class ContentPage extends React.Component {
  state = {
    baseImgWidth: 2560,
    baseImgHeight: 1536,
    videoFile: null,
    fullscreen: false,
    showMe: false,
  }

  openVideo = file => this.setState({ videoFile: file })
  openFullscreenVideo = file =>
    this.setState({ videoFile: file, fullscreen: true })

  closeVideo = () => this.setState({ videoFile: null, fullscreen: false })

  handleCloseContent = () => {
    this.setState({ showMe: false })
    // TODO: timer
    setTimeout(this.props.onClose, 450)
  }

  componentDidMount() {
    //console.log('did')
    if (this.props.show) {
      setTimeout(() => this.setState({ showMe: true }), 250)
    }
  }
  render() {
    const {
      bg,
      menuData,
      windowWidth,
      windowHeight,
      title,
      body,
      backIcon,
      backSlug,
      attachments,
      attachmentIcons,
      transition,
      currentSlug,
      hotspots,
      hotspotLabel,
      showHotspotsBorder,
      onClose,
      show,
    } = this.props
    //const windowWidth = 1366
    //const windowHeight = 1024
    const { videoFile, fullscreen, baseImgWidth, baseImgHeight } = this.state
    const windowRatio = windowWidth / windowHeight
    const imageRatio = baseImgWidth / baseImgHeight
    const wrapperWidth =
      imageRatio > windowRatio ? windowWidth : windowHeight * imageRatio
    const wrapperHeight =
      imageRatio > windowRatio ? windowWidth / imageRatio : windowHeight

    console.log('content page is here')
    return (
      <Wrapper shown={this.state.showMe} style={transition && transition.style}>
        {videoFile ? (
          <VideoPlayer
            v={videoFile}
            fullscreen={fullscreen}
            closeVideo={this.closeVideo}
          />
        ) : null}

        <Content shown={this.state.showMe}>
          <Title>{title}</Title>
          <Body dangerouslySetInnerHTML={{ __html: body }} />
          {attachments && attachments.length > 0 && false && (
            <Title>Weiterführende Informationen</Title>
          )}

          {attachments && attachments.length > 0 && (
            <Attachments
              onStartVideo={this.openVideo}
              icons={attachmentIcons}
              links={attachments}
            />
          )}
        </Content>

        <BackButton
          shown={this.state.showMe}
          onClick={this.handleCloseContent}
          icon={backIcon}
        >
          Schliessen
        </BackButton>

        <Background shown={this.state.showMe} />
      </Wrapper>
    )
  }
}

export default ContentPage
