import React from 'react'
import Helmet from 'react-helmet'
import styled, { createGlobalStyle } from 'styled-components'
import styledNormalize from 'styled-normalize'

import * as fonts from './Fonts'

const GlobalStyle = createGlobalStyle`

	${styledNormalize}
  @font-face {
    font-family: "telegrotesk";
    src: url(${fonts.TeleGroteskRegularWOFF2}) format("woff2"), url(${fonts.TeleGroteskRegularWOFF}) format("woff");
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: "telegrotesk";
    src: url(${fonts.TeleGroteskBoldWOFF2}) format("woff2"), url(${fonts.TeleGroteskBoldWOFF}) format("woff");
    font-weight: 700;
    font-style: normal;
  }
  @font-face {
    font-family: "telegrotesk";
    src: url(${fonts.TeleGroteskUltraWOFF2}) format("woff2"), url(${fonts.TeleGroteskUltraWOFF}) format("woff");
    font-weight: 900;
    font-style: normal;
  }
	html, body, #___gatsby, #gatsby-focus-wrapper {
		height: 100%;
	//width: 1366px;
	//height: 1024px;
	}
	#gatsby-focus-wrapper > div:not(.dachstory) {
		height: 100% !important;
	}
	.tl-wrapper { height: 100%; }
`

const Root = styled.div`
  position: relative;
	width: 100%;
  height: 100%;
  //margin: 0;
  //padding: 0;
	min-width: 1024px;
	min-height: 768px;
	//max-width: 1976px;
	//width: 1366px;
	//height: 1024px;
	//height: 100%

  position: relative;
  //&:before {
  //  display: block;
  //  content: "";
  //  width: 100%;
  //  padding-top: calc((465 / 683) * 100%);
  //}


}

`
const Content = styled.div`
  //height: 100%;
  //min-width: 1024px;
  //width: 1366px;
  //height: 1024px;
  //border: 1px solid red;
  //overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  & > div:not(.dachstory) {
    height: 100%;
  }
`

const TemplateWrapper = ({ children }) => (
  <Root>
    <Helmet
      title="Deutsche Telekom - Industry Now"
      meta={[
        { name: 'description', content: '' },
        { name: 'keywords', content: '' },
        { name: 'viewport', content: 'width=1366' },
      ]}
    />
    <GlobalStyle />
    <Content>{children}</Content>
  </Root>
)

export default TemplateWrapper
