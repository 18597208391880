import React from 'react'
import styled from 'styled-components'

import HotSpot from './HotSpot'

const HotSpotsWrapper = styled.div`
  position: absolute;
  //width: ${props => props.w}px;
  //height: ${props => props.h}px;
  //min-width: 1024px;
  //min-height: 768px;
  //max-width: 1440px;
  //max-height: 1080px;
  ////width: 1366px;
  ////height: 1024px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

class HotSpots extends React.Component {
  state = {
    //baseImgWidth: 2560,
    //baseImgHeight: 1536,
    baseImgWidth: 2732,
    baseImgHeight: 2048,
  }

  render() {
    const { hotspots, hotspotLabel, showBorder, size, animation } = this.props
    const { baseImgWidth, baseImgHeight } = this.state
    //const windowWidth = 1366
    //const windowHeight = 1024
    //const windowRatio = windowWidth / windowHeight
    //const imageRatio = baseImgWidth / baseImgHeight
    //const wrapperTargetWidth =
    //  imageRatio > windowRatio ? windowWidth : windowHeight * imageRatio
    //const wrapperTargetHeight =
    //  imageRatio > windowRatio ? windowWidth / imageRatio : windowHeight

    //const wrapperWidth =
    //  wrapperTargetWidth < 1024
    //    ? 1024
    //    : wrapperTargetWidth > 1440
    //    ? 1440
    //    : wrapperTargetWidth
    //const wrapperHeight =
    //  wrapperTargetHeight < 768
    //    ? 786
    //    : wrapperTargetHeight > 1080
    //    ? 1080
    //    : wrapperTargetHeight

    //const wPc = (wrapperWidth / baseImgWidth) * 100
    //const hPc = (wrapperHeight / baseImgHeight) * 100

    //console.log(wrapperWidth, wPc, ' - ', wrapperHeight, hPc)

    //x={(h.hotspotX / 100) * wPc}
    //y={(h.hotspotY / 100) * hPc}
    //

    return (
      <HotSpotsWrapper className="hotspots">
        {hotspots &&
          hotspots.length &&
          hotspots.length > 0 &&
          hotspots.map(
            h =>
              h.icon &&
              h.hotspotX &&
              h.hotspotY && (
                <HotSpot
                  x={(h.hotspotX / baseImgWidth) * 100}
                  y={(h.hotspotY / baseImgHeight) * 100}
                  icon={h.icon.url}
                  slug={h.slug}
                  key={h.slug}
                  showLabel={hotspotLabel}
                  title={h.displayTitle || h.title}
                  showBorder={showBorder}
                  size={size || 'normal'}
                  animation={animation}
                />
              )
          )}
      </HotSpotsWrapper>
    )
  }
}

export default HotSpots
