import React from 'react'
import styled from 'styled-components'
import Link from 'gatsby-link'

const H = styled.h3`
  text-align: right;
  font-size: 28px;
  letter-spacing: 1px;
  margin-bottom: 0.2em;
`
const Title = styled.h4`
  font-size: 25px;
  margin-top: 0;
  margin-bottom: 1.3em;
  letter-spacing: 0.8px;
`
const Subtitle = styled.h5`
  font-size: 19px;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 4px;
  letter-spacing: 0.8px;
`
const Wrapper = styled.div`
  display: flex;
  text-align: right;
`
const CategoryCol = styled.div`
  flex: 1;
  width: 250px;
  margin-left: 50px;
`
const SubCategoryWrapper = styled.div`
  margin-bottom: 18px;
`

const ImgWrap = styled.div`
  height: 200px;
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  img {
    margin-top: auto;
    max-width: 200px;
    max-height: 100%;
  }
`
const Ul = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`
const Li = styled.li`
  font-size: 19px;
  line-height: 1.3em;
  a {
    color: #fff;
    text-decoration: none;
  }
`

const Category = ({ d }) =>
  d ? (
    <CategoryCol>
      <ImgWrap>
        <img alt=" " src={d.menuIcon ? d.menuIcon.url : ''} />
      </ImgWrap>
      <Title>{d.displayTitle}</Title>
      {d.treeChildren.map(group => (
        <SubCategory d={group} />
      ))}
    </CategoryCol>
  ) : null

const SubCategory = ({ d }) => (
  <SubCategoryWrapper>
    <Subtitle>{d.displayTitle}</Subtitle>
    <Ul>
      {d.treeChildren.map(link => (
        <Li>
          <Link to={`/${link.slug}`}>{link.displayTitle}</Link>
        </Li>
      ))}
    </Ul>
  </SubCategoryWrapper>
)

export default ({ data }) => (
  <div>
    <H>Szenarien</H>

    <Wrapper>
      <Category d={data.treeChildren[1]} />
      <Category d={data.treeChildren[2]} />
      <Category d={data.treeChildren[0]} />
    </Wrapper>
  </div>
)
