import React from 'react'
import styled from 'styled-components'
import { Player, BigPlayButton } from 'video-react'
import 'video-react/dist/video-react.css'

const Video = styled.div`
  position: absolute;
  display: flex;
  z-index: 8000;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.9);
  align-items: center;
  justify-content: center;
`
const CloseButton = styled.a`
  width: 40px;
  height: 40px;
  position: absolute;
  top: 10px;
  right: 50px;
  z-index: 8001;
  cursor: pointer;
  span {
    position: absolute;
    background: black;
    height: 4px;
    width: 29px;
    right: 0px;
    top: 50%;
    margin-top: -2px;
    transition: 0.5s;
    &:first-child {
      top: 50%;
      transform: rotate(405deg);
    }
    &:last-child {
      top: 50%;
      transform: rotate(-405deg);
    }
  }
`

const PlayerWrapper = styled.div`
  width: ${props => (props.fullscreen ? '100%' : '960px')};
`

export default props => {
  return (
    <Video>
      <CloseButton onClick={props.closeVideo}>
        <span />
        <span />
      </CloseButton>
      <PlayerWrapper fullscreen={props.fullscreen}>
        <Player autoPlay controls={false} src={props.v}>
          <BigPlayButton position="center" />
        </Player>
      </PlayerWrapper>
    </Video>
  )
}
