import React from 'react'
import styled from 'styled-components'
import { navigate } from 'gatsby'

import HotSpots from './HotSpots'
import Header from './Header'
import Menu from './Menu'
import TopMenu from './TopMenu'
import VideoPlayer from './VideoPlayer'
import ContentPage from './ContentPage'
import BackButton from './BackButton'
import DachStory from './Dach'

import PlayButton from './../images/play-button-gray.png'

const Wrapper = styled.div`
  position: relative;
	//background: white url('${props => props.bg}') 0 0  no-repeat;
	//background-size: contain;
	height: 100%;
	overflow: hidden;
	background: linear-gradient(180deg, rgba(208,208,208,1) 0%, rgba(248,248,248,1) 100%);
	img {
	position: absolute;
		max-height: 100%;
		max-width: 100%;
	}
`

const Attachment = styled.a`
  display: flex;
  position: absolute;
  flex-direction: column;
  text-align: center;
  color: #4d4d4d;
  font-family: telegrotesk;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
  bottom: 30px;
  left: ${props => (props.idx ? 24 + props.idx * 184 : 24)}px;
  cursor: pointer;

  height: 160px;

  > img:first-child {
    border: 1px solid #e20074;
  }

  .icon {
    max-height: 50px;
    position: absolute;
    bottom: 75px;
    right: 3px;
    margin-left: -20px;
  }

  .play-button {
    max-height: 60px;
    position: absolute;
    top: 15px;
    left: 50%;
    margin-left: -30px;
  }

  span {
    position: absolute;
    bottom: 17px;
    width: 100%;
    text-align: center;
    display: block;
    font-size: 17px;
    height: 40px;
  }
`

const Outer = styled.div`
  position: relative;
  width: 100%;
  //max-width: 1440px;
  max-width: 1200px;
  max-height: 1080px;
  margin: 0 auto;
  overflow: hidden;
`
const ContentWrapper = styled.div`
  position: relative;
  display: block;
  width: 100%;
  padding-top: 75%;
`
const Content = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`

const VideoStarter = ({ data, onClick, idx }) => (
  <Attachment idx={idx} onClick={() => onClick(data.videoUrl)}>
    <img
      alt="thumbnail"
      width={160}
      height={90}
      src={data.thumbnail ? data.thumbnail.url : ''}
    />
    <img alt="play" className="play-button" src={PlayButton} />
    <span>{data.title}</span>
  </Attachment>
)

const PdfStarter = ({ data, onClick, idx }) => (
  <Attachment idx={idx} href={data.filename} target="_blank">
    <img
      alt="pdf"
      width={160}
      height={90}
      src={data.thumbnail ? data.thumbnail.url : ''}
    />
    <span>{data.title}</span>
  </Attachment>
)

class Page extends React.Component {
  state = { videoFile: null, fullscreen: false }
  componentDidMount() {
    //console.log('did')
  }

  openVideo = file => this.setState({ videoFile: file })
  openFullscreenVideo = file =>
    this.setState({ videoFile: file, fullscreen: true })

  closeVideo = () => this.setState({ videoFile: null, fullscreen: false })

  handleCloseContent = () => {
    navigate(this.props.backSlug)
    console.log('close me and change slug', this.props.backSlug)
  }
  handleGoBack = e => {
    e.preventDefault()
    navigate(this.props.backLink)
  }

  render() {
    const {
      bg,
      hotspots,
      children,
      hotspotLabel,
      showHotspotsBorder,
      logoColor,
      backLink,
      backLinkX,
      backLinkY,
      icon,
      lvl,
      menuData,
      transition,
      attachments,
      currentSlug,
      showContent,
      contentTitle,
      contentBody,
      contentAttachments,
      contentAttachmentIcons,
      backIcon,
      backSlug,
      backButton,
      animation,
      showDachStory,
    } = this.props
    const { videoFile, fullscreen } = this.state

    var link = null
    if (backLink && icon && backLinkY && backLinkX) {
      link = {
        hotspotX: backLinkX,
        hotspotY: backLinkY,
        title: ' ',
        icon: icon,
        slug: backLink.slug,
      }
    }
    //console.log('lvl', lvl, hotspots)
    return (
      // bg={bg}
      <Wrapper
        className="wrapper"
        style={transition && false && transition.style}
      >
        {videoFile ? (
          <VideoPlayer
            v={videoFile}
            fullscreen={fullscreen}
            closeVideo={this.closeVideo}
          />
        ) : null}
        <Header logoColor={logoColor} />
        <TopMenu data={menuData} lvl={lvl} currentSlug={currentSlug} />
        {/*<Menu lvl={lvl} data={menuData} openVideo={this.openFullscreenVideo} />*/}

        {attachments && attachments.length > 0
          ? attachments.map((a, i) =>
              a.__typename === 'DatoCmsVideo' ? (
                <VideoStarter
                  onClick={this.openFullscreenVideo}
                  data={a}
                  key={i}
                  idx={i}
                />
              ) : a.__typename === 'DatoCmsPdf' ? (
                <PdfStarter data={a} key={i} idx={i} />
              ) : null
            )
          : null}
        {children}
        <Outer>
          <ContentWrapper>
            <Content>
              {showDachStory && <DachStory />}
              {link ? (
                <HotSpots
                  hotspots={[link]}
                  hotspotLabel={false}
                  showBorder={true}
                  size="big"
                />
              ) : null}
              <HotSpots
                hotspots={hotspots}
                hotspotLabel={hotspotLabel}
                showBorder={showHotspotsBorder}
                animation={animation}
              />
              <img src={bg} />
              {showContent && (
                <ContentPage
                  title={contentTitle}
                  body={contentBody}
                  attachments={contentAttachments}
                  attachmentIcons={contentAttachmentIcons}
                  backIcon={backIcon}
                  onClose={this.handleCloseContent}
                  show={showContent}
                />
              )}
              {backButton && <BackButton to={this.props.backLink} />}
            </Content>
          </ContentWrapper>
        </Outer>
      </Wrapper>
    )
  }
}

export default Page
