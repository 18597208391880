import React from 'react'
import styled from 'styled-components'

import thumb from '../images/Handel_im_Wandel.png'

const Wrapper = styled.div`
  position: absolute;
  display: inline-block;
  width: 300px;
  height: 220px;
  background-color: rgba(56, 56, 56, 0.76);
  color: white;
  z-index: 19;
  top: 124px;
  padding-left: 94px;
  padding-top: 30px;
  a {
    color: #ffffff;
    font-family: 'telegrotesk';
    font-size: 29px;
    font-weight: 900;
    text-transform: uppercase;
    text-decoration: none;
  }
  p {
    margin: 0;
  }
  img {
    width: 260px;
  }
`

const DachStory = () => (
  <Wrapper className="dachstory">
    <a target="_blank" href="/_handelimwandel.pdf">
      <p>Handel im Wandel</p>
      <img src={thumb} />
    </a>
  </Wrapper>
)

export default DachStory
