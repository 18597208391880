import React from 'react'
import styled from 'styled-components'
//import Link from 'gatsby-link'
//import TransitionLink from 'gatsby-plugin-transition-link'
import AniLink from 'gatsby-plugin-transition-link/AniLink'

const HS = styled(AniLink)`
  position: absolute;
  width: ${props => (props.size === 'normal' ? 62 : 80)}px;
  height: ${props => (props.size === 'normal' ? 51 : 80)}px;
  //padding: 10px
  border: ${props => props.withBorder && '1px solid white'};
  border-radius: 50%;
  background: ${props => props.withBorder && 'rgba(255, 255, 255, 0.41)'};
  top: ${props => props.y - 4 || 0}%;
  left: ${props => props.x - 3 || 0}%;
  cursor: pointer;
`

const Icon = styled.img`
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 2;
`

const Label = styled.span`
  position: absolute;
  z-index: 1;
  color: #fff;
  background: #e20074;
  text-transform: uppercase;
  font-family: sans-serif;
  border: 1px solid #a5225a;
  border-radius: 4px;
  font-size: 18px;
  line-height: 34px;
  left: 38px;
  top: 6px;
  padding-left: 24px;
  padding-right: 21px;
  white-space: nowrap;
  font-family: telegrotesk;
  font-weight: 400;
  letter-spacing: 0px;
`

export default ({
  size,
  x,
  y,
  icon,
  slug,
  showLabel,
  title,
  showBorder,
  animation = true,
}) => (
  <HS
    cover={animation}
    bg={'#f20074'}
    duration={1}
    size={showLabel ? 'normal' : 'big'}
    to={`/${slug}`}
    x={x}
    y={y}
    withBorder={showBorder}
  >
    <Icon src={icon} />
    {showLabel ? <Label>{title}</Label> : null}
  </HS>
)
