import React from 'react'

export default ({ color = '#e20074' }) => (
  <svg
    viewBox="0 0 76 38"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <polygon
        id="path-1"
        points="0.0003 0.795 30.5083 0.795 30.5083 37.9999 0.0003 37.9999"
      />
    </defs>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(0.000000, -1.000000)">
        <polygon
          id="Fill-1"
          fill={color}
          points="3.5199 18.1144 -0.0001 18.1144 -0.0001 25.6484 7.5339 25.6484 7.5339 18.1144"
        />
        <polygon
          id="Fill-2"
          fill={color}
          points="22.9742 25.6485 22.9742 18.1145 26.4822 18.1145 30.5082 18.1145 30.5082 25.6485"
        />
        <polygon
          id="Fill-3"
          fill={color}
          points="45.5757 25.6485 45.5757 18.1145 49.1967 18.1145 53.1097 18.1145 53.1097 25.6485"
        />
        <polygon
          id="Fill-4"
          fill={color}
          points="71.6616 18.1144 68.1776 18.1144 68.1776 25.6484 75.7116 25.6484 75.7116 18.1144"
        />
        <g id="Group-7" transform="translate(0.000000, 0.205100)">
          <mask id="mask-2" fill="white">
            <use xlinkHref="#path-1" />
          </mask>
          <g id="Clip-6" />
          <path
            d="M30.1353,0.7949 L0.3723,0.7949 L0.0003,13.9229 L1.9743,14.2639 C2.3423,10.3959 3.3573,7.5249 5.0203,5.6519 C6.7703,3.6919 9.1793,2.6689 12.2773,2.5809 L12.2773,30.0439 C12.2773,32.4419 11.9423,33.9999 11.2423,34.7179 C10.6473,35.3309 9.6403,35.7069 8.2233,35.8469 C7.8023,35.8819 7.0853,35.8989 6.0703,35.8989 L6.0703,37.9999 L24.4373,37.9999 L24.4373,35.8989 C23.4223,35.8989 22.7053,35.8819 22.2843,35.8469 C20.8673,35.7069 19.8603,35.3309 19.2653,34.7179 C18.5653,33.9999 18.2263,32.4419 18.2263,30.0439 L18.2263,2.5809 C21.3243,2.6689 23.7383,3.6919 25.4883,5.6519 C27.1513,7.5249 28.1663,10.3959 28.5333,14.2639 L30.5083,13.9229 L30.1353,0.7949 Z"
            id="Fill-5"
            fill={color}
            mask="url(#mask-2)"
          />
        </g>
      </g>
    </g>
  </svg>
)
