import React from 'react'
import styled from 'styled-components'

import Link from 'gatsby-link'
import Logo from './Logo'

const HeadWrapper = styled.header`
  position: absolute;
  width: 100%;
  z-index: 100;
  background: #e20074;
`
const ContentWrapper = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  //max-width: 1440px;
  max-width: 1200px;
  margin: 0 auto;
`

const H1 = styled.h1`
  margin: 0;
  padding: 0;
  padding-left: 96px;
  height: 30px;

  a {
    text-decoration: none;
    span {
      color: ${props => props.color || '#ffffff'};
      text-transform: uppercase;
      font-family: 'telegrotesk';
      font-weight: 900;
      font-size: 14.585px;
      line-height: 30px;
      vertical-align: super;
    }
  }

  svg {
    height: 100%;
    margin-right: 15px;
  }
`

const H2 = styled.h2`
  text-transform: uppercase;
  font-family: 'telegrotesk';
  font-weight: 900;
  font-size: 14.585px;
  color: white;
  margin-left: auto;
  margin-right: 55px;
`

const Header = ({ logoColor }) => (
  <HeadWrapper>
    <ContentWrapper>
      <H1 color={logoColor}>
        <Link to="/">
          <Logo color={logoColor || 'white'} />
          <span> Digital Landscape</span>
        </Link>
      </H1>
      <H2>Erleben, was verbindet.</H2>
    </ContentWrapper>
  </HeadWrapper>
)

export default Header
